import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
      return (
          <div>
              <NavMenu />
        <Container>
          {this.props.children}
              </Container>
              <footer className="footer border-top text-muted" style={{ marginTop: "50px" }}>
                  <div className="container">
                      &copy; 2021 - Евраз. Тестовый стенд. - <a href="https://statanly.com/">Statanly Technologies</a>
                  </div>
              </footer>
      </div>
    );
  }
}
