import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import CanvasJSReact from './assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

var dps = [];   //dataPoints.
var xVal = dps.length + 1;
var yVal = 15;
var updateInterval = 1000;

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
      super(props);
      this.updateChart = this.updateChart.bind(this);
      this.start = this.start.bind(this);
      this.stop = this.stop.bind(this);
      this.getScreen = this.getScreen.bind(this);
      this.minValue = this.minValue.bind(this);
      this.renderForecastsTable = this.renderForecastsTable.bind(this);
      this.setInitialState = this.setInitialState.bind(this); 
      this.timeConverter = this.timeConverter.bind(this);
      this.setStart = this.setStart.bind(this);
      this.setEnd = this.setEnd.bind(this);      
      this.listScreen = this.listScreen.bind(this); // setOnly
      this.setOnly = this.setOnly.bind(this);
      this.state = {
          setIntervalId: "", unixTime: 0, start: null, end: null, screens: [], only: false,
          forecasts: [], loading: true, average: 0, day: 0, month: 0, currentState: "",
          year: 0, hour: 0, minute: 0, second: 0, distants: 0, t: "",
          compressedWarningDistance: null, compressedEmergencyDistance: null, uncompressedWarningDistance: null,
          uncompressedEmergencyDistance: null, compressedDistance: null, uncompressedDistance: null, currentTime: null, currentDistance: null, status: null, screen: null
      };
  }

  componentDidMount() {
      //this.populateWeatherData();
      //setInterval(this.updateChart, updateInterval);
      this.setInitialState();

    }

    async start() {
        const token = await authService.getAccessToken();

        fetch('api/data/start', {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(data => {
                this.setState({
                    status: data.response,
                    setIntervalId: setInterval(this.updateChart, updateInterval)
                });
                //setInterval(this.updateChart, updateInterval);
            });        
    }
    async stop() {
        const token = await authService.getAccessToken();

        fetch('api/data/stop', {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(data => {
                this.setState({
                    status: data.response
                });
                clearInterval(this.state.setIntervalId);
                //setInterval(this.updateChart, 10000000);
            });
    }
    async getScreen(id) {
        const token = await authService.getAccessToken();

        fetch('api/data/screen?id=' + id, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(data => {
                this.setState({
                    screen: data.screen
                });                
            });
    }
    async listScreen() {
        if (this.state.start == null || this.state.end == null) { return;}
        const token = await authService.getAccessToken();

        fetch('api/data/listscreen?start=' + this.state.start + '&end=' + this.state.end, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(data => {
                this.setState({
                    screens: data
                });
            });
    }
    timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }
    minValue(a, b) {
        if (Math.abs(a) >= Math.abs(b)) { return b; }
        return a;

    }
    setStart(str) {   
        this.setState({ start: str });
    }
    setEnd(str) {
        this.setState({ end: str });
    }
    setScreen(str) {
        this.setState({ screen: str });
    }
    setOnly(str) {
        var only = this.state.only;
        if (only) { only = false }
        else { only = true}
        this.setState({ only: only });
    }
    async setInitialState() {
        //yVal = yVal + Math.round(5 + Math.random() * (-5 - 5));
        const token = await authService.getAccessToken();

        fetch('api/data/config', {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(data => {      
                this.setState({
                    compressedWarningDistance: data.compressedWarningDistance, compressedEmergencyDistance: data.compressedEmergencyDistance,
                    uncompressedWarningDistance: data.uncompressedWarningDistance, uncompressedEmergencyDistance: data.uncompressedEmergencyDistance,
                    compressedDistance: data.compressedDistance, uncompressedDistance: data.uncompressedDistance
                });
            });
    }
    async updateChart() {
        //yVal = yVal + Math.round(5 + Math.random() * (-5 - 5));
        const token = await authService.getAccessToken();

        fetch('api/data/get', {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(data => {
                yVal = data.y;
                xVal = new Date(data.x);
                var cS = "сжатия";
                if (Math.abs(this.state.compressedDistance - yVal) >= Math.abs(this.state.uncompressedDistance - yVal)) { cS = "расжатия"}
                var temptData = this.state.forecasts;
                //temptData.push({ screen: data.screen, id: data.id, x: xVal.getDate() + "." + xVal.getMonth() + "." + xVal.getFullYear() + " " + xVal.getHours() + ":" + xVal.getMinutes() + ":" + xVal.getSeconds(), y: yVal, z: data.z });

                var contains = false;
                for (let counter = 0; counter < temptData.length; counter++) {
                    if (temptData[counter].id == data.id) { contains = true; }
                }
                if (!contains && data.x != 0) {
                    temptData.push({ screen: data.screen, id: data.id, x: this.timeConverter(data.x), y: yVal, z: data.z });
                    dps.push({ x: data.x, y: yVal });

                    if (temptData.length > 5000) { temptData.shift(); }

                    if (xVal.getSeconds() >= 59) {
                        dps = [];
                    }
                    if (dps.length > 30) {
                        dps.shift();
                    }
                    if (this.chart != undefined && dps.length != 0)
                        this.chart.render();
                    this.setState({
                        forecasts: temptData, loading: false, day: new Date(data.x).getDate(),
                        month: new Date(data.x).getMonth(), year: new Date(data.x).getFullYear(),
                        hour: new Date(data.x).getHours(), minute: new Date(data.x).getMinutes(),
                        second: new Date(data.x).getSeconds(),
                        currentDistance: yVal, unixTime: data.x, currentState: cS
                    });
                }
            });
    }
    render() {
        if (dps.length === 0) { dps = []}
        const options = {
            title: {
                text: "Динамика расстояний"
            },
            data: [{
                type: "line",
                dataPoints: dps
            }]
        }
        var elmt = (this.state.forecasts.map(x => x.y));
        var sum = 0;
        for (var i = 0; i < elmt.length; i++) {
            sum += elmt[i];
        }
        //var details = this.start.t === "" ? <div></div> : <div>Время {this.state.t} Расстояние {this.state.distants}</div>
        var average = (sum / elmt.length).toFixed(2);;
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForecastsTable(this.state.forecasts.reverse(), average);
        var warningMessage = Math.abs(this.state.currentDistance - this.state.warningDistance) > this.state.warningDistance ?
            <div className="alert alert-warning" role="alert">
                <strong>Warning!</strong> Расстояние привысило {this.state.compressedWarningDistance}
            </div> : <div></div>;
        var emergencyMessage = this.state.currentDistance > this.state.compressedEmergencyDistance ?
            <div className="alert alert-danger" role="alert">
                <strong>Oh snap!</strong> Расстояние превысило {this.state.compressedEmergencyDistance}
            </div> : <div></div>;
        var downloadpath = "/api/data/download?start=" + this.state.start + "&end=" + this.state.end + "&only=" + this.state.only;
        return (           
            <div>
                <h1>Аналитика</h1>
                <CanvasJSChart options={options}
                    onRef={ref => this.chart = ref}
                />
                <div className="row">
                    <div className="col-md-2"> <button onClick={this.start}>Старт</button> </div>
                    <div className="col-md-2"> <button onClick={this.stop}>Стоп</button> </div>

                    <div className="col-md-3"> <p id="tabelLabel" >Среднее <strong>{average}</strong></p> </div>
                    <div className="col-md-3"><p id="tabelLabel" >Время <strong>{this.timeConverter(this.state.unixTime)}</strong></p> </div>
                    <div className="col-md-3"><p id="tabelLabel" >Режим <strong>{this.state.currentState}</strong></p> </div>
                </div>
                <h3>Таблица расстояний</h3>
                <div className="row">
                    <div className="col-md-6">
                        {contents}
                        <div>
                            <label>Начало</label>
                            <input type="datetime-local" className="form-control" name="start" value={this.state.start} onChange={(e) => this.setStart(e.target.value)}></input>
                            <label>Конец</label>
                            <input type="datetime-local" className="form-control" name="end" value={this.state.end} onChange={(e) => this.setEnd(e.target.value)}></input>
                            <button onClick={this.listScreen}>Найти</button>
                            <table className='table table-striped' aria-labelledby="tabelLabel" style={{ overflowY: "scroll", height: "300px", display: "block" }}>
                                <thead>
                                    <tr>
                                        <th>Время</th>
                                        <th>Расстояние</th>
                                        <th>Отклонение</th>
                                        <th>Скрин</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.screens.map((forecast, key) =>
                                        <tr key={forecast.time}>
                                            <td>{this.timeConverter(forecast.time)}</td>
                                            <td>{forecast.distance / 100}</td>
                                            <td>{(this.minValue(forecast.distance / 100 - this.state.uncompressedDistance, forecast.distance / 100 - this.state.compressedDistance)).toFixed(2)}</td>
                                            <td> <a href='#' onClick={(e) => { e.preventDefault(); this.setScreen(forecast.screen); }}>Скрин</a></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <p>Статус: <strong>{this.state.status}</strong></p>
                        <p>Режим сжатия: <strong>{this.state.compressedDistance}</strong></p>
                        <div className="row">
                            <div className="col-md-6">Предупредительная уставка: <strong>{this.state.compressedWarningDistance}</strong></div>
                            <div className="col-md-6">Аварийная уставка: <strong>{this.state.compressedEmergencyDistance}</strong></div>
                        </div>
                        <p style={{ marginTop: "15px" }}>Режим разжатия : <strong>{this.state.uncompressedDistance}</strong></p>
                        <div className="row">
                            <div className="col-md-6">Предупредительная уставка: <strong>{this.state.uncompressedWarningDistance}</strong></div>
                            <div className="col-md-6">Аварийная уставка: <strong>{this.state.uncompressedEmergencyDistance}</strong></div>
                        </div>
                        <div className="row">
                            <div></div>
                            <div></div>
                        </div>
                        <div style={{ height: "600px" }}>
                            <img src={"data:image/png;base64, " + this.state.screen }></img>
                        </div>
                    </div>
                </div>    
                <div className="row">
                    <label>Начало</label>
                    <input type="datetime-local" className="form-control" name="start" value={this.state.start} onChange={(e) => this.setStart(e.target.value)}></input>
                    <label>Конец</label>
                    <input type="datetime-local" className="form-control" name="end" value={this.state.end} onChange={(e) => this.setEnd(e.target.value)}></input>
                    <label>Только критические отклонения</label>
                    <input className="form-control col-md-2" type="checkbox" value={this.state.only} onClick={(e) => this.setOnly(e.target.value)}></input>
                    <a className="form-control col-md-2" href={downloadpath} target="_blank">Выгрузить</a>
                </div>
            </div>
        );
    }
    renderForecastsTable(forecasts, average) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel" style={{ overflowY: "scroll", height: "300px", display: "block" }}>
                <thead>
                    <tr>
                        <th>Время</th>
                        <th>Расстояние</th>
                        <th>Отклонение</th>
                        <th>Секунда</th>
                    </tr>
                </thead>
                <tbody>
                    {forecasts.map((forecast, key) =>                      
                        <tr style={{ backgroundColor: forecast.screen ? "red" : "#c2ff99" }} key={forecast.date}>
                            <td>{forecast.x}</td>
                            <td>{forecast.y}</td>
                            <td>{(this.minValue(forecast.y - this.state.uncompressedDistance, forecast.y - this.state.compressedDistance)).toFixed(2)}</td>
                            <td> <a href='#' onClick={(e) => { e.preventDefault(); this.getScreen(forecast.id); }}>Скрин</a></td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

  render1() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderForecastsTable(this.state.forecasts);

    return (
      <div>
        <h1 id="tabelLabel" >Weather forecast</h1>
        <p>This component demonstrates fetching data from the server.</p>
        {contents}
      </div>
    );
  }

  async populateWeatherData() {
    const token = await authService.getAccessToken();
    const response = await fetch('weatherforecast', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
                    
