import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './assets/css/style.css'

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { token: null };
        this.populateWeatherData = this.populateWeatherData.bind(this);

    }

    componentDidMount() {
        this.populateWeatherData();
    }
    async populateWeatherData() {
        const token = await authService.getAccessToken();
        this.setState({ token: token });
    }
    render() {
        //var token = authService.getAccessToken().result;
        var result = <div>Hello</div>;
        result = this.state.token == null ?
            <body style={{ backgroundColor: "white" }}>
                <section  id="hero">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
                                <div data-aos="zoom-out">
                                    <h1 className="text-center text-lg-start" style={{ color: "#444444" }}><span style={{ color: "#444444" }}>ЕВРАЗ</span> Нижнетагильский металлургический комбинат</h1>
                                    <h2 style={{ color: "#444444" }}>Система оперативного анализа и контроля работы тормозной системы главного скипового подъема доменной печи</h2>
                                    <div className="text-center text-lg-start">
                                    </div>
                                </div>
                            </div>                          
                        </div>
                        <div className="row">
                            <div className="text-center text-lg-start col-lg-4">
                                <a href="/authentication/login" className="btn-get-started scrollto">Войти</a>
                            </div>
                                <div className="col-lg-8 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
                                    <img src="/assets/img/NT.jpg" className="img-fluid animated" alt=""></img>
                        </div>
                        </div>
                    </div>                  

                </section>
            </body>
            :
            <div>
                <h1>Система оперативного анализа и контроля работы тормозной системы главного скипового подъема доменной печи.</h1>
                <p>Система оперативного анализа и контроля работы тормозной системы главного скипового подъема доменной печи на основе алгоритмов машинного зрения»
                с использованием технологий машинного обучения</p>
                <ul>
                    <li><a href='/fetch-data'>Анализ расстояний</a></li>
                    <li><a href='/home/Download'>Документация</a></li>
                    <li><a href='/home/Download'>Презентация</a> </li>
                </ul>
                <p>Техническое задание</p>
                <ul>
                    <li><strong>ПРЕДПОСЫЛКИ</strong>. «ЕВРАЗ» — одна из ведущих международных вертикально-интегрированных металлургических и горнодобывающих компаний. Следуя важнейшему
                        приоритету компании — постоянному улучшению качества сервиса и оптимизации ресурсов, компания постоянно использует возможность современных технологий
                        анализа данных и искусственного интеллекта с целью улучшения качества и оптимизации различных процессов компании.
                </li>
                    <li><strong>ЦЕЛИ ПРОЕКТА</strong>.
                        Построение системы оперативного анализа и контроля на основе алгоритмов машинного зрения для оптимизации промышленных процессов компании.
                </li>
                    <li><strong>СОДЕРЖЕНИЕ РАБОТ</strong>.
                       В рамках данного проекта требуется разработать алгоритмы анализа и контроля различных признаков в процессе работы различных агрегатов на производстве. В частности,
                       контроль фактического разжатия колодок и предупреждение об отклонение от заданного значения, окончательный список которых будет определен на этапе формирования технического задания. Алгоритм должен принимать на вход видеопоток/видео файл, а на выходе давать результаты в виде анализа и предупреждений об отклонениях в формате, определяемом на этапе формирования технического задания.
                       В рамках проекта подразумевается применение различных методов и архитектур для моделей, решающих поставленную задачу, а также использование разметки данных с последующим обогащением информации.
                    </li>
                </ul>
            </div>;

        return (
            <div> {result}</div>
        );
    }
    render1() {
        //var token = authService.getAccessToken().result;
        var result = <div>Hello</div>;
        result = this.state.token == null ?
            <div>
                <h2 className="center">Система оперативного анализа и контроля работы тормозной системы главного скипового подъема доменной печи</h2>
                <div className="row">
                    <div className="col-md-12">
                        <a className="btn btn-primary" href="/authentication/login">Войти</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <img src="./assets/img/nt.jpg"></img>
                    </div>
                </div>
            </div>
            :
            <div>
                <h1>Система оперативного анализа и контроля работы тормозной системы главного скипового подъема доменной печи.</h1>
                <p>Система оперативного анализа и контроля работы тормозной системы главного скипового подъема доменной печи на основе алгоритмов машинного зрения»
                с использованием технологий машинного обучения</p>
                <ul>
                    <li><a href='/fetch-data'>Анализ расстояний</a></li>
                    <li><a href='/'>Документация</a></li>
                    <li><a href='/'>Презентация</a> </li>
                </ul>
                <p>Техническое задание</p>
                <ul>
                    <li><strong>ПРЕДПОСЫЛКИ</strong>. «ЕВРАЗ» — одна из ведущих международных вертикально-интегрированных металлургических и горнодобывающих компаний. Следуя важнейшему
                        приоритету компании — постоянному улучшению качества сервиса и оптимизации ресурсов, компания постоянно использует возможность современных технологий
                        анализа данных и искусственного интеллекта с целью улучшения качества и оптимизации различных процессов компании.
                </li>
                    <li><strong>ЦЕЛИ ПРОЕКТА</strong>.
                        Построение системы оперативного анализа и контроля на основе алгоритмов машинного зрения для оптимизации промышленных процессов компании.
                </li>
                    <li><strong>СОДЕРЖЕНИЕ РАБОТ</strong>.
                       В рамках данного проекта требуется разработать алгоритмы анализа и контроля различных признаков в процессе работы различных агрегатов на производстве. В частности,
                       контроль фактического разжатия колодок и предупреждение об отклонение от заданного значения, окончательный список которых будет определен на этапе формирования технического задания. Алгоритм должен принимать на вход видеопоток/видео файл, а на выходе давать результаты в виде анализа и предупреждений об отклонениях в формате, определяемом на этапе формирования технического задания.
                       В рамках проекта подразумевается применение различных методов и архитектур для моделей, решающих поставленную задачу, а также использование разметки данных с последующим обогащением информации.
                    </li>
                </ul>
            </div>;

        return (
            <div> {result}</div>
        );
    }
}
